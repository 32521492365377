/* eslint-disable max-len */
// ant design
import { message } from 'antd';
import { Common, color } from '@stockbit/ui';
import { isClient } from 'constants/app';
import Image from 'global/Image';
import { IconWrapper } from './styled';

const { Iconmoon, Flex } = Common;

/**
 * handleErrorMessageAPI to activate message
 * @param {string} content
 * @param {string} type - "alert-green" | "alert-red" | "alert-orange" | "alert-blue" | "snackbar-green" | "plain-green"
 * @param {=boolean} hasCloseIcon
 */
const handleErrorMessageAPI = (content, type, hasCloseIcon = false) => {
  if (!isClient()) return null;

  // destroy last message alert
  message.destroy();

  let Icon = null;
  const closeIcon = hasCloseIcon ? (
    <Image src="/icon/alert-close.svg" onClick={() => message.destroy()} />
  ) : null;

  if (type === 'alert-green') {
    Icon = (
      <IconWrapper>
        <Iconmoon
          size="14px"
          className="icon-toolbar_check"
          color={color.light}
        />
      </IconWrapper>
    );
  } else if (type === 'snackbar-green') {
    Icon = <Image src="/icon/alert-success.svg" />;
  }

  // show alert
  message.open({
    content: (
      <div className={type} data-cy="global-alert-message">
        <Flex align="center">
          {Icon}
          <span>{content}</span>
          {closeIcon}
        </Flex>
      </div>
    ),
    duration: 3,
    style: {
      top: '50px',
      fontFamily: 'ProximaNovaRg',
      left: 0,
      margin: 'auto',
      position: 'fixed',
      right: 0,
      maxWidth: '320px',
    },
    className: 'sysmsg-content',
    onClick: () => message.destroy(),
  });
};

export default handleErrorMessageAPI;
