import { color, Common } from '@stockbit/ui';
import styled from 'styled-components';

const { IconWrapper: UIIconWrapper } = Common;

const IconWrapper = styled(UIIconWrapper)`
  background: ${color.primary};
  border-radius: 50%;
  margin-right: 10px;
`;

// eslint-disable-next-line import/prefer-default-export
export { IconWrapper };
